import Layout from './components/Layout';
import './App.css';
import dataes from './data.json';
import dataen from './data.en.json';

import { LanguageContext } from './context/LanguageContext';
import { useState } from 'react';

function App() {
  const [path, setPath] = useState(window.location.pathname);

  let data = dataes;
  if (path === '/en') data = dataen;

  return (
    <LanguageContext.Provider value={{ path, setPath }}>
      <Layout data={data} />
    </LanguageContext.Provider>
  );
}

export default App;
