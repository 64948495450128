import Section from './Section';
import './Technologies.css';


function Technologies({ name, children }) {
  return (
    <Section name={name}>
      {children.map(c => (<TechnologieElement key={c.name} {...c} />))}
    </Section>
  );
}

function TechnologieElement({ icon, name, knowledge }) {
  return (
    <div className="section-element section-technologie">
      {icon && <h3 className="section-icon">{icon}</h3>}
      <div className="technologie-name">{name}</div>
      <div className={`technologie-knowledge knowledge-${knowledge}`}><div className='techonlogie-filler'></div></div>
    </div>
  );
}

export default Technologies;