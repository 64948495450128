import { LanguageContext } from '../context/LanguageContext';
import './Section.css';
import { useContext, useEffect, useRef } from 'react';
import TextRoll from './TextRoll';


function Section({ name, children }) {
  return (
    <section>
      <TextRoll>{name}</TextRoll>
      {children}
    </section>
  );
}

function SectionLines({ name, children }) {
  return (
    <Section name={name}>
      {children.map((c, i) => (<SectionElement key={i} {...c} />))}
    </Section>
  );
}

function SectionElement({ icon, iconHref, iconBack, tooltip, dateRange, role, lines }) {

  return (
    <div className="section-element">
      {icon && <SectionIcon icon={icon} iconHref={iconHref} iconBack={iconBack} tooltip={tooltip} />}
      <div className="section-lines">
        <div className="section-role">{role}</div>
        {lines.map((l, i) => typeof (l) === 'string' ? <p key={i}>{l}</p> : <a key={i} href={l.href}>{l.text}</a>)}
        <div className="section-dates">{dateRange}</div>
      </div>
    </div>
  );
}

function SectionIcon({ icon, iconHref, iconBack, tooltip }) {
  const { setPath } = useContext(LanguageContext)

  const onClick = (e) => {
    window.history.replaceState(`state${iconHref}`, '', iconHref);
    setPath(iconHref);
  }

  return (
    <div className="section-icon-container" title={tooltip}
      onClick={iconHref ? onClick : null}>
      <div className={iconBack && "section-icon-container-spin"} >
        {iconBack && <img src={process.env.PUBLIC_URL + iconBack} className="section-icon-back" alt="icon" />}
        <img src={process.env.PUBLIC_URL + icon}
          alt="icon"
          className={`section-icon ${iconHref ? 'pointer' : ''}`}
        />
      </div>
    </div>
  );
}

export default Section;
export { SectionLines };