import React, { useState, useLayoutEffect } from "react";
import "./TextRoll.css";
import { usePrevious } from '../components/Hooks';


export default function TextRoll({ children }) {
  const [oldChildren, setOldChildren] = useState(null);
  const prev = usePrevious(children);
  useLayoutEffect(() => {
    setOldChildren(prev);
  }, [children]);

  return (
    <div className="container">
      <div key={children} className={`text-roll ${oldChildren ? 'rolling' : ''}`}>
        {oldChildren && <h2>{oldChildren}</h2>}
        <h2>{children}</h2>
      </div>
    </div>
  );
}
