import { useRef, useEffect } from "react";


export const usePrevious = (value) => {
  const ref = useRef();
  // const curr = useRef();
  useEffect(() => {
    // if (curr.current !== value)
    ref.current = value;
    // curr.current = value;
  }, [value]);
  return ref.current;
}


