import MediaQuery from 'react-responsive'
import { SectionLines } from './Section';
import ProfilePicture from './ProfilePicture';
import Technologies from './Technologies';
import Starter from './Starter';
import photo from '../assets/photo.png';
import './Layout.css';
import Language from './Language';

const Layout = ({ data }) => (
  <div>
    <MediaQuery minDeviceWidth={768}>
      <DesktopLayout data={data} />
    </MediaQuery>
    <MediaQuery maxDeviceWidth={768}>
      <MobileLayout data={data} />
    </MediaQuery>
  </div>
)

const DesktopLayout = ({ data }) => (
  <div className="App">
    <header>
      <ProfilePicture src={photo} name="photo" />
      <SectionLines {...data.contact} />
      <SectionLines {...data.personalInfo} />
      <Technologies {...data.technologies} />
    </header>
    <main>
      <h1>{data.name}</h1>
      {data.sections.map((s, i) => <SectionLines key={i} {...s} />)}
    </main>
    <footer>
      <Starter {...data.starter} />
    </footer>
  </div>
);

const MobileLayout = ({ data }) => (
  <div className="App mobile">
    <header>
      <ProfilePicture src={photo} name="photo" />
      <h1>{data.name}</h1>
    </header>
    <main>
      {data.sections.map((s, i) => <SectionLines key={i} {...s} />)}
    </main>
    <footer>
      <Technologies {...data.technologies} />
      <SectionLines {...data.personalInfo} />
      <SectionLines {...data.contact} />
      <Starter {...data.starter} />
    </footer>
  </div>
)

export default Layout;
