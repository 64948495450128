import React from 'react';
import Section from './Section';
import bulbasaur from '../assets/bulbasaur.png';
import pokeball from '../assets/pokeball.png';
import star from '../assets/star.png';
import anime from 'animejs';
import './Starter.css'


const SECTION_STATES = {
  none: 0,
  go: 1,
  catch: 2
};

function Starter({ name }) {
  const target = 3;
  const [sectionState, setSectionState] = React.useState(SECTION_STATES.none);
  const [playingAnimation, setPlayingAnimation] = React.useState(false);
  const bottomRef = React.useRef(null);

  function handleTargetReached() {
    if (!playingAnimation) {
      switch (sectionState) {
        case SECTION_STATES.none:
          setSectionState(SECTION_STATES.go);
          setPlayingAnimation(true);
          window.setTimeout(() => bottomRef.current.scrollIntoView({ behavior: "smooth" }), 200);
          break;
        case SECTION_STATES.go:
          setSectionState(SECTION_STATES.catch);
          setPlayingAnimation(true);
          break;
        default:
          setSectionState(SECTION_STATES.none);
          setPlayingAnimation(false);
          break;
      }
    }
  }
  function handleAnimationEnded() {
    setPlayingAnimation(false);
    switch (sectionState) {
      case SECTION_STATES.none:
        setSectionState(SECTION_STATES.go);
        setPlayingAnimation(true);
        break;
      case SECTION_STATES.go:
        setSectionState(SECTION_STATES.catch);
        setPlayingAnimation(true);
        break;
      case SECTION_STATES.catch:
      default:
        setSectionState(SECTION_STATES.none);
        setPlayingAnimation(false);
        break;
    }
  }

  return (
    <Section name={name}>
      <Title target={target} sectionState={sectionState} playingAnimation={playingAnimation} onTargetReached={handleTargetReached} />
      <PokemonAnimation sectionState={sectionState} onAnimationEnded={handleAnimationEnded} />
      <div style={{ float: "left", clear: "both" }}
        ref={bottomRef}>
      </div>
    </Section>
  );
}

function PokemonAnimation({ sectionState, onAnimationEnded }) {
  const [goAnimation, setGoAnimation] = React.useState(null);
  const [catchAnimation, setCatchAnimation] = React.useState(null);

  React.useEffect(() => {
    if (SECTION_STATES.go === sectionState)
      if (goAnimation) {
        goAnimation.restart();
      } else {
        const path = anime.path('path.go-path');
        const tl = anime.timeline({ complete: onAnimationEnded });
        tl.add({
          targets: '.starter-container',
          height: 100,
          duration: 100
        });
        tl.add({
          targets: '.starter-pkball',
          duration: 0,
          opacity: 1,
          zIndex: 2
        });
        tl.add({
          targets: '.starter-pkball',
          easing: 'easeInOutQuad',
          duration: 1000,
          translateX: path('x'),
          translateY: path('y'),
          rotate: path('angle'),
        });
        tl.add({
          targets: '.starter-pkmn',
          keyframes: [
            { top: -10, left: 140, scale: 0, opacity: 0, zIndex: 3, duration: 0 },
            { scale: 1, opacity: 1, duration: 800, easing: 'easeOutQuad', endDelay: 200 },
            { translateX: 6, duration: 200, easing: 'easeOutQuad' },
            { translateX: 0, duration: 200, easing: 'easeOutQuad' },
            { translateX: 6, duration: 200, easing: 'easeOutQuad' },
            { translateX: 0, duration: 200, easing: 'easeOutQuad' },
            { duration: 600 }
          ]
        });
        setGoAnimation(tl);
      }
    if (SECTION_STATES.catch === sectionState)
      if (catchAnimation) {
        catchAnimation.restart();
      } else {
        const path = anime.path('path.catch-path');
        const tl = anime.timeline({ complete: onAnimationEnded });
        tl.add({
          targets: '.starter-pkmn',
          zIndex: 1,
          duration: 0
        });
        tl.add({
          targets: '.starter-pkball',
          easing: 'easeInOutQuad',
          duration: 1000,
          translateX: path('x'),
          translateY: path('y'),
          rotate: path('angle'),
        });
        tl.add({
          duration: 200,
          easing: 'easeOutQuad',
          targets: '.starter-pkball',
          rotate: 0,
        });
        tl.add({
          duration: 600,
          easing: 'easeOutQuad',
          targets: '.starter-pkmn',
          scale: 0,
          left: 50,
          top: -20,
          zIndex: 1,
          delay: anime.stagger(100)
        });
        tl.add({
          duration: 400,
          easing: 'spring(1, 100, 10, 0)',
          targets: '.starter-pkball',
          translateX: 80,
          translateY: 55,
          zIndex: 1,
          rotate: 0,
        });
        tl.add({
          targets: '.starter-pkball',
          keyframes: [
            { rotate: -40, duration: 250, easing: 'easeOutQuad' },
            { rotate: 40, duration: 250, easing: 'easeOutQuad' },
            { rotate: 0, duration: 250, easing: 'easeOutQuad' },
            { rotate: -40, duration: 250, easing: 'easeOutQuad', delay: 200 },
            { rotate: 40, duration: 250, easing: 'easeOutQuad' },
            { rotate: 0, duration: 250, easing: 'easeOutQuad' },
            { rotate: -40, duration: 250, easing: 'easeOutQuad', delay: 200 },
            { rotate: 40, duration: 250, easing: 'easeOutQuad' },
            { rotate: 0, duration: 250, easing: 'easeOutQuad' },
            { scale: 1.5, duration: 400, translateY: 55, easing: 'easeOutQuad', delay: anime.stagger(200) },
          ],
          easing: 'easeOutElastic(1, .8)',
        });
        tl.add({
          targets: '.starter-star.star-left', keyframes: [
            { translateX: -30, translateY: -30, rotate: 360, opacity: 1, duration: 400, easing: 'easeOutQuad', delay: anime.stagger(100) },
          ]
        })
        tl.add({
          targets: '.starter-star.star-middle', keyframes: [
            { translateX: 0, translateY: -35, rotate: 360, opacity: 1, duration: 400, easing: 'easeOutQuad', delay: anime.stagger(100) },
          ]
        })
        tl.add({
          targets: '.starter-star.star-right', keyframes: [
            { translateX: 30, translateY: -30, rotate: 360, opacity: 1, duration: 400, easing: 'easeOutQuad', delay: anime.stagger(100) },
          ]
        })
        tl.add({ targets: '.starter-star, .starter-pkball', opacity: 0, duration: 600, delay: 1200 });
        tl.add({ targets: '.starter-container', height: 0 });
        setCatchAnimation(tl);
      }
  }, [sectionState]);

  return (<div className='starter-container'>
    <img src={star} className='starter-star star-left' alt="star" />
    <img src={star} className='starter-star star-middle' alt="star" />
    <img src={star} className='starter-star star-right' alt="star" />
    <img src={pokeball} className='starter-pkball' alt="pokeball" />
    <img src={bulbasaur} className='starter-pkmn' alt="bulbasaur" />
    <svg width="190" height="60" viewBox="0 0 190 60" >
      <path className='go-path' fill="none" stroke="none" strokeWidth="1" d="
      M120,0 
      Q200,00,230,50 
      Q185,00,150,50">
      </path>
    </svg>
    <svg width="190" height="60" viewBox="0 0 190 60" >
      <path className="catch-path" fill="none" strokeWidth="1" stroke="none" d="
      M0,20 
      Q70,10,150,30 
      Q120,10,80,20">
      </path>
    </svg>
  </div>)

}

function Title({ target, sectionState, playingAnimation, onTargetReached }) {
  const [counter, setCounter] = React.useState(0);
  const [resetTimer, setResetTimer] = React.useState(null);
  const [visibleTimer, setVisibleTimer] = React.useState(null);

  const incrementCounter = () => {
    if (!playingAnimation) {
      // increment counter
      const nc = counter === target ? 1 : counter + 1;
      setCounter((c) => nc);
      if (nc === target) {
        onTargetReached();
      }

      // reset timer
      const resetTimerId = setTimeout(() => {
        setResetTimer(v => null);
        setCounter(0);
      }, 1000);
      setResetTimer(v => {
        if (v) clearTimeout(v);
        return resetTimerId;
      });

      // visible timer
      const visibleTimerId = setTimeout(() => {
        setVisibleTimer(v => null);
      }, 500);
      setVisibleTimer(v => {
        if (v) clearTimeout(v);
        return visibleTimerId;
      });
    }
  };

  const counterVisible = visibleTimer !== null;
  return (
    <p className={`starter-name ${sectionState > SECTION_STATES.none ? 'highlighted' : ''}`} onClick={incrementCounter}>#001 Bulbasaur
      <span className={`starter-counter ${counterVisible ? 'visible' : ''}`} >{counter}</span>
    </p>

  );
}


export default Starter;
